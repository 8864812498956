var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { attrs: { id: "product-details" } },
    [
      !_vm.loaded || (!_vm.product && !_vm.products)
        ? _c(
            "b-row",
            {
              staticClass: "pt-4 mt-4",
              staticStyle: { "min-height": "140px" },
            },
            [_c("ct-centered-spinner")],
            1
          )
        : _c(
            "div",
            [
              _vm.productKind === "filing_product"
                ? _c("filing-checkout", {
                    attrs: { product: _vm.product, company: _vm.company },
                    on: { "product-added": _vm.productAdded },
                  })
                : _vm.productKind === "product_bundle"
                ? _c("bundle-checkout", {
                    attrs: { bundle: _vm.product, company: _vm.company },
                    on: { "product-added": _vm.productAdded },
                  })
                : _vm.productKind === "simple_product"
                ? _c("simple-checkout", {
                    attrs: { product: _vm.product, company: _vm.company },
                    on: {
                      completed: function ($event) {
                        return _vm.productAdded(_vm.product)
                      },
                    },
                  })
                : _vm.productKind === "subscription_bundle"
                ? _c("subscription-bundle-checkout", {
                    attrs: { product: _vm.product, company: _vm.company },
                  })
                : _vm.productKind === "supply_and_consult_product"
                ? _c("supply-consultation-checkout", {
                    attrs: { product: _vm.product, company: _vm.company },
                    on: {
                      completed: function ($event) {
                        return _vm.productAdded(_vm.product)
                      },
                    },
                  })
                : _c("registered-agent-checkout", {
                    attrs: { products: _vm.products, company: _vm.company },
                    on: { "product-added": _vm.productAdded },
                  }),
            ],
            1
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }