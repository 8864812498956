<template>
  <div id="product-details">
    <b-row v-if="!loaded || (!product && !products)" class="pt-4 mt-4" style="min-height: 140px;">
      <ct-centered-spinner />
    </b-row>
    <div v-else>
      <filing-checkout v-if="productKind === 'filing_product'" :product="product" :company="company" @product-added="productAdded" />
      <bundle-checkout v-else-if="productKind === 'product_bundle'" :bundle="product" :company="company" @product-added="productAdded" />
      <simple-checkout v-else-if="productKind === 'simple_product'" :product="product" :company="company" @completed="productAdded(product)" />
      <subscription-bundle-checkout v-else-if="productKind === 'subscription_bundle'" :product="product" :company="company" />
      <supply-consultation-checkout v-else-if="productKind === 'supply_and_consult_product'" :product="product" :company="company" @completed="productAdded(product)" />
      <registered-agent-checkout v-else :products="products" :company="company" @product-added="productAdded" />
    </div>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'

  export default {
    name: 'ProductDetails',

    components: {
      FilingCheckout:             () => import('@/components/HireUs/FilingCheckout'),
      SimpleCheckout:             () => import('@/components/HireUs/SimpleCheckout'),
      BundleCheckout:             () => import('@/components/HireUs/BundleCheckout'),
      SubscriptionBundleCheckout: () => import('@/components/HireUs/SubscriptionBundleCheckout'),
      RegisteredAgentCheckout:    () => import('@/components/HireUs/RegisteredAgentCheckout'),
      SupplyConsultationCheckout: () => import('@/components/HireUs/SupplyConsultationCheckout'),
      CtCenteredSpinner,
    },

    props: {
      productId: String,
      companyId: String,
    },

    data() {
      return {
        loaded: false,
      }
    },

    computed: {
      ...mapGetters('products', [
        'products',
      ]),
      ...mapGetters('jurisdictions', [
        'jurisdictions',
      ]),
      ...mapGetters('checkout', [
        'findProduct',
        'productsLoaded',
      ]),
      ...mapGetters('companies', {
        findCompany: 'find',
      }),
      routeParams() {
        return this.$store.state.appRouter.params
      },
      company() { return this.findCompany(this.routeParams.companyId) },
      product() { return this.routeParams.product || this.findProduct(this.productId) },
      productKind() { return this.routeParams.product?.kind || this.product?.kind },
    },
    async mounted() {
      this.$emit('toggleButtonVisibility', { backButtonHidden: false, addContactButtonHidden: true })

      if (!this.jurisdictions.length) {
        await this.loadJurisdictions()
      }

      await this.$store.dispatch('companies/loadIfNotAvailableById', {
        id: this.companyId || this.routeParams.companyId,
      })

      // TODO One of these is using Checkout store, one Products store.
      await this.ensureProductsLoaded()
      await this.getProducts(this.routeParams)
      await this.determineTrialEligibilityMonitoring(this.companyId)
      await this.determineLawOnCallTrialEligibility(this.companyId || this.routeParams.companyId,)

      this.loaded = true
    },
    methods: {
      ...mapActions('products', ['getProducts']),
      ...mapActions('jurisdictions', { loadJurisdictions: 'load' }),
      ...mapActions('lawOnCall', ['determineLawOnCallTrialEligibility']),
      ...mapActions('checkout', ['loadProducts']),
      ...mapActions('monitoring', [
      'determineTrialEligibilityMonitoring',
    ]),
      async ensureProductsLoaded() {
        if (!this.productsLoaded) await this.loadProducts(this.companyId)
      },

      productAdded(product) {
        this.$root.$emit('log-interaction', { type: 'button', name: 'product-added', subName: product.name })
      },
    },
  }
</script>
